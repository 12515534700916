<template>
  <div class="register-page">
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="11" lg="5">
          <v-card class="shadow rounded-lg">

            <v-overlay :value="saveLoading"
                       color="white"
                       opacity="0.8">
              <v-progress-circular color="primary"
                                   indeterminate
                                   size="50"
              ></v-progress-circular>
            </v-overlay>

            <v-card-text class="pa-4 pa-lg-8">

              <div class="text-center">

                <!--                <img alt="log"-->
                <!--                     src="../../assets/logo2.png"/>-->

                <h2 class="pt-6 pb-4 register-title">
                  Inscription
                </h2>

                <p class="fs-15">
                  Tous les champs avec une étoile <span class="red--text">(*)</span> sont obligatoires.
                </p>

              </div>

              <v-alert v-if="success"
                       border="left"
                       dark
                       dense
                       dismissible
                       prominent
                       text
                       type="success">
                Compte créé avec succès.
              </v-alert>

              <v-text-field v-model="form.installer_name"
                            :error-messages="errors.installer_name"
                            class="mb-5"
                            dense
                            hide-details="auto"
                            label="Nom animateur"
                            outlined
                            prepend-inner-icon="mdi-account"
                            @input='errors && errors.installer_name ? errors.installer_name= "": false'/>

              <v-text-field v-model="form.fullname"
                            :error-messages="errors.fullname"
                            class="mb-5"
                            dense
                            hide-details="auto"
                            label="Nom et prénom(*)"
                            outlined
                            prepend-inner-icon="mdi-account"
                            @input='errors && errors.fullname ? errors.fullname= "": false'/>


              <v-text-field v-model="form.phone"
                            :error-messages="errors.phone"
                            class="mb-5 mt-2"
                            dense
                            hide-details="auto"
                            label="Numéro de téléphone(*)"
                            outlined
                            prepend-inner-icon="mdi-phone"
                            @input='errors && errors.phone ? errors.phone= "": false'/>

              <v-text-field v-model="form.password"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :error-messages="errors.password"
                            :type="show ? 'text' : 'password'"
                            class="mb-5 mt-2"
                            dense
                            hide-details="auto"
                            label="Mot de passe(*)"
                            outlined
                            prepend-inner-icon="mdi-lock"
                            @input='errors && errors.password ? errors.password= "": false'
                            @click:append="show = !show"/>

              <v-text-field v-model="form.code"
                            :error-messages="errors.code"
                            class="mb-5"
                            dense
                            hide-details="auto"
                            label="Code POS(*)"
                            outlined
                            prepend-inner-icon="mdi-code-array"
                            @input='errors && errors.code ? errors.code= "": false'/>

              <div class="text-center cursor-pointer" @click="onScan = true">

                <v-icon :color="form.gifty_id ? 'success' : ''" size="80">mdi-qrcode-scan</v-icon>

                <p :class="form.gifty_id ? 'success--text':''" class="pt-4">
                  <v-icon v-if="form.gifty_id" class="mt--4" color="success" size="30">mdi-check-circle</v-icon>
                  Scanner le QR code gifty
                </p>

              </div>

              <v-alert v-if="form.gifty_id" border="left" color="success" dense prominent text>

                <span class="d-block fs-15">
                <strong>Nom :</strong> {{ user.name }}
                </span>

                <span class="d-block fs-15 mt-2">
                 <strong>N°téléphone :</strong> {{ user.phone }}
                </span>

              </v-alert>

              <v-btn :disabled="!form.gifty_id || !form.phone || !form.fullname || !form.password || !form.code"
                     :loading="btnLoading"
                     block
                     class="mt-8"
                     color="primary"
                     depressed
                     large
                     @click="registerValidation">
                Créer le compte
              </v-btn>

              <v-btn block
                     class="mt-4"
                     color="primary"
                     large
                     text
                     @click="resetForm">
                <v-icon left>mdi-refresh</v-icon>
                Réinitialiser le formulaire
              </v-btn>

            </v-card-text>
          </v-card>


          <v-dialog v-model="onScan" :fullscreen="$vuetify.breakpoint.mobile" max-width="500" persistent>
            <v-card>
              <v-card-title>
                Scanner le QR code gifty
                <v-spacer/>
                <v-icon @click="onScan = false">mdi-close</v-icon>
              </v-card-title>
              <v-divider/>
              <v-card-text class="pa-4">
                <v-overlay :value="scanLoading"
                           color="white"
                           opacity="0.8">
                  <v-progress-circular color="primary"
                                       indeterminate
                                       size="50"
                  ></v-progress-circular>
                </v-overlay>
                <div v-if="onScan">
                  <qrcode-stream @decode="onDecode"/>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>

          <p class="text-center mt-8">
            Copyright © {{ (new Date()).getFullYear() }}
            <a class="text-decoration-none font-weight-bold"
               href="https://gifty-dz.com"
               target="_blank">Gifty DZ</a>, All rights Reserved
          </p>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import {QrcodeStream} from 'vue-qrcode-reader'

export default {
  components: {
    QrcodeStream
  },
  data() {
    return {
      btnLoading: false,
      errors: {},
      form: {
        code: '',
        phone: '',
        fullname: '',
        latitude: '',
        longitude: '',
        password: '',
        gifty_id: '',
        installer_name: '',
      },
      show: true,

      onScan: false,
      scanLoading: false,

      saveLoading: false,
      success: false,

      user: {},
    }
  },
  methods: {
    registerValidation() {
      this.errors = {}
      this.btnLoading = true
      axios.post(process.env.VUE_APP_BASE_URL + 'salepoint/register/validation', this.form).then(() => {
        this.btnLoading = false
        this.register()
      }).catch(err => {
        this.btnLoading = false
        this.errors = err.response.data.errors;
        this.$errorMessage = "Une erreur s'est produite, veuillez réessayer !"
        console.log(err)
      })
    },
    register() {
      this.saveLoading = true
      axios.post(process.env.VUE_APP_BASE_URL + 'salepoint/register', this.form).then(() => {
        this.saveLoading = false
        this.success = true
        this.resetForm()
        this.$vuetify.goTo(0)
      }).catch(err => {
        this.saveLoading = false
        this.errors = err.response.data.errors;
        this.$errorMessage = "Une erreur s'est produite, veuillez réessayer !"
        console.log(err)
      })
    },
    onDecode(result) {
      if (result) {
        if (result.toString().length > 6) {
          let res = JSON.parse(result)
          let userId = res.userId
          this.getAccountId(userId)
        } else {
          this.getAccountId(result)
        }
      }
    },
    getAccountId(userId) {
      this.scanLoading = true
      axios.get(process.env.VUE_APP_GIFTYACCOUNT_API_URL + '/' + userId).then((res) => {
        this.scanLoading = false
        this.onScan = false
        this.user = res.data
        this.form.gifty_id = res.data.account.id
      }).catch(err => {
        this.scanLoading = false
        this.onScan = false
        this.$errorMessage = "Une erreur s'est produite, veuillez réessayer !"
        console.log(err)
      })
    },
    resetForm() {
      this.form = {
        code: '',
        phone: '',
        fullname: '',
        latitude: 0,
        longitude: 0,
        password: '',
        gifty_id: '',
        installer_name: '',
      }
    },
  },
}
</script>

<style>

.theme--light.v-text-field > .v-input__control > .v-input__slot::before {
  border-color: rgba(62, 62, 62, 0.3) !important;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
  border-color: rgba(62, 62, 62, 0.2) !important;
}

.theme--light.v-label {
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
}

.register-page {
  background: #e5ecf6;
  min-height: 100vh
}

.register-title {
  font-size: 1.5rem;
  color: #534F5A
}
</style>
